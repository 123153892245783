body {
  background-color: rgb(18, 18, 19);
  color: white;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
}
.App {
  text-align: center;
}

#wordle-word {
  font-size: 8vw;
}

#form-container {
  width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 1000px) {
  #form-container {
      width: 30%;
  }

  #wordle-word {
      font-size: 3vw;
  }
}

#clickable-container {
  display: flex;
  justify-content: center;
}

.letter-box {
  position:relative;
  font-size: 3rem;
  font-weight: bold;
  width: 4rem;
  height: 4rem;
  margin: 0.4rem;
}

.clickable-letter {
  background-color: rgb(83, 141, 78);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.disappearing-box {
  border: 2px solid #3a3a3c;
  background-color: rgb(18, 18, 19);
  text-align:center;
  position:absolute;
  top: 0px;
  left: 0px;
  cursor:pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  box-sizing: border-box;
}
